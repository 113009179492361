import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Real1 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_01.jpg'
import Real2 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_02.jpg'
import Real3 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_03.jpg'
import Real4 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_04.jpg'
import Real5 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_05.jpg'
import Real6 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_06.jpg'
import Real7 from '../../../images/work/08_PB_WALKER/PHOTOS/WA_07.jpg'
import Real1s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_01.jpg'
import Real2s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_02.jpg'
import Real3s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_03.jpg'
import Real4s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_04.jpg'
import Real5s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_05.jpg'
import Real6s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_06.jpg'
import Real7s from '../../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_07.jpg'
import Process1 from '../../../images/work/08_PB_WALKER/PROCESS/WAL_01.jpg'
import Process2 from '../../../images/work/08_PB_WALKER/PROCESS/WAL_02.jpg'
import Process1s from '../../../images/work/08_PB_WALKER/PROCESS/450x280/WAL-SM_01.jpg'
import Process2s from '../../../images/work/08_PB_WALKER/PROCESS/450x280/WAL-SM_02.jpg'

const Residence08 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PEBBLE BEACH RESIDENCE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={['Remodel of existing 2,640 SF single family residence. Addition of 800 SF of floor area to a total of 3,440 SF. Relocation of existing driveway. Outdoor improvements.', 'Design - 2014', 'Construction - 2015-2016', 'General Contractor: Cam Construction, Inc', 'Structural Engineer: Duckbrew, Inc.', 'Photography: Black Sky, Paul Black']}
        testimonialList={[]}
        photos={[Real1,Real2,Real3,Real4,Real5,Real6,Real7]}
        photosSmall={[Real1s,Real2s,Real3s,Real4s,Real5s,Real6s,Real7s]}
        processPhotos={[Process1,Process2]}
        processPhotosSmall={[Process1s,Process2s]}
        />
    </Container>
  </Layout>
)

export default Residence08;
